import Qs from 'qs';
import { UploadedFile, UserData } from 'types/interfaces';

import { createAuthedHttpClient } from '@driftt/web-auth';

import config from 'config';

import {
  isHowToVideosFolder,
  isRootPersonalLibraryFolder,
  isRootTeamLibraryFolder,
} from '../utils/folderUtils';

export const CustomerAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.CUSTOMER_API,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
  },
});

export const VideoAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.VIDEO_API,
    withCredentials: true,
  },
});

const MeetingsAPI = createAuthedHttpClient({
  baseConfig: {
    baseURL: config.MEETINGS_API,
  },
});

export async function updateUser({ userId, update }) {
  return VideoAPI.patch(`/apis/v1/users/${userId}/`, update);
}

export async function updateVideoTitle({ videoId, value }) {
  const response = await VideoAPI.patch(`/apis/v1/videos/${videoId}/`, {
    title: value,
    title_edited: true,
  });

  return response.data;
}

export async function fetchAssociationToVideo(videoId) {
  return VideoAPI.get(`/apis/v1/videos/${videoId}/get_association_to_video/`);
}

export async function fetchVideoIdForHash(hash) {
  return VideoAPI.get(`/apis/v1/videos/get-id/${hash}`);
}

export const fetchVideoData = (videoId, jwt) => {
  const url = `/apis/v1/player/videos/${videoId}/` + (jwt ? `?jwt=${jwt}` : '');
  return VideoAPI.get(url).then(({ data }) => data);
};

export async function updateVideoData({ videoId, update }) {
  const response = await VideoAPI.patch(`/apis/v1/videos/${videoId}/`, update);

  return response.data;
}

export async function updateVideoSources({ videoSourceId, update }) {
  const response = await VideoAPI.patch(`/apis/v1/video-sources/${videoSourceId}/`, update);

  return response.data;
}

export async function deleteVideo({ videoId }) {
  const response = await VideoAPI.patch(`/apis/v1/videos/${videoId}/`, {
    message: 1,
    status: 4,
  });

  return response.data;
}

export async function fetchVideoPermissions({ videoId }) {
  const response = await VideoAPI.get(`/apis/v1/videos/${videoId}/permissions/`);

  return response.data;
}

export async function updateVideoPermissions({ videoId, update }) {
  const response = await VideoAPI.post(`/apis/v1/videos/${videoId}/permissions/bulk/`, update);

  return response.data;
}

export async function fetchAllPrivacyGroups() {
  const response = await VideoAPI.get('/apis/v1/privacy-team/');

  return response.data;
}

export async function createPrivacyGroup({ name }) {
  const response = await VideoAPI.post(`/apis/v1/privacy-team/`, { team_name: name });

  return response.data;
}

export async function updatePrivacyGroup({ id, permissions }) {
  const response = await VideoAPI.post(`/apis/v1/privacy-team/${id}/permissions/bulk/`, {
    permissions,
  });

  return response.data;
}

export async function updatePrivacyGroupName({ id, name }) {
  const response = await VideoAPI.put(`/apis/v1/privacy-team/${id}/`, {
    team_name: name,
  });

  return response.data;
}

export async function fetchPrivacyGroupPermissions({ id }) {
  const response = await VideoAPI.get(`/apis/v1/privacy-team/${id}/permissions/`);

  return response.data;
}

export async function fetchVideoViews({ videoId, cursor = null, pageSize = 5 }) {
  const response = await VideoAPI.get(`/apis/v1/videos/${videoId}/views/`, {
    params: {
      cursor,
      page_size: pageSize,
    },
  });

  return response.data;
}

export async function getVideoDownloadUrls({ videoId }) {
  const response = await VideoAPI.get(`/apis/v1/videos/${videoId}/get_download_urls/`);

  return response.data;
}

export async function getCaptionDownloads({ videoId }) {
  const response = await VideoAPI.get(`/apis/v1/videos/${videoId}/get_caption_downloads/`);

  return response.data;
}

export async function fetchPlayerOptions(videoId, jwt) {
  const url = `/apis/v1/player/player-options/${videoId}/` + (jwt ? `?jwt=${jwt}` : '');
  const response = await VideoAPI.get(url);
  return response.data;
}

export async function updatePlayerOptions({ optionsId, options }) {
  const payload = {
    data: options,
  };
  const response = await VideoAPI.patch(`/apis/v1/player-options/${optionsId}/`, payload);

  return response.data;
}

export async function updateVideoVisibility({ videoId, videoVisibility }) {
  const response = await VideoAPI.patch(`/apis/v1/videos/${videoId}/visibility/`, videoVisibility);
  return response.data;
}

export async function fetchUserCustomization() {
  const response = await VideoAPI.post('/apis/v1/users-customization/');

  return response.data;
}

export async function fetchUserDetails() {
  const response = await VideoAPI.get('/apis/v1/users/get_detail/');

  return response.data;
}

export async function fetchVideoUserForDriftUser() {
  const response = await VideoAPI.get('/apis/v1/users/get_video_user_for_drift_user/');

  return response.data;
}

// This Create API Call takes care of 'update as well as create' on the backend
export async function createOrUpdateUserCustomization(customization) {
  const response = await VideoAPI.post('/apis/v1/users-customization/', customization);

  return response.data;
}

export async function downloadVideoReport(startDate, endDate, searchTerm = '') {
  const response = await VideoAPI.get(`/apis/v1/reporting/videos/?export=true`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      search_term: searchTerm,
    },
  });

  return response.data;
}

export async function downloadTeamVideoReport(startDate, endDate) {
  const response = await VideoAPI.get(
    `/apis/v1/reporting/videos/by_user/?export=true&ordering=videos_created`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  );

  return response.data;
}

export async function fetchVideoStatsByUser(
  startDate,
  endDate,
  sortField,
  sortAscending = false,
  filterMap,
) {
  const response = await VideoAPI.get('/apis/v1/reporting/videos/by_user/', {
    params: {
      start_date: startDate,
      end_date: endDate,
      ordering: (sortAscending ? '-' : '') + sortField,
      filter_map: filterMap,
    },
  });

  return response.data;
}

export async function createGifFromVideo({
  gifCropHeight,
  gifCropWidth,
  gifCropX,
  gifCropY,
  gifEndTime,
  gifStartTime,
  videoId,
  streamType,
  title,
  titleEdited,
}) {
  const STATUS_NEW = 1;
  const SOURCE_TYPE_GIF = 2;
  const VIDEO_FORMAT_VP9 = 3;
  const FILE_FORMAT_WEBM = 2;

  const response = await VideoAPI.post('/apis/v1/videos/', {
    gifSourceVideoId: videoId,
    sourceType: SOURCE_TYPE_GIF,
    status: STATUS_NEW,
    title,
    titleEdited,
    videoSourceSet: [
      {
        fileFormat: FILE_FORMAT_WEBM,
        sourceType: SOURCE_TYPE_GIF,
        videoFormat: VIDEO_FORMAT_VP9,
        streamType,
      },
    ],
    extra_data: {
      gifCropHeight,
      gifCropWidth,
      gifCropX,
      gifCropY,
      gifEndTime,
      gifStartTime,
    },
  });

  return response.data;
}

export async function uploadToS3({
  file,
  S3,
  accessKey,
  secretKey,
  sessionToken,
  bucket,
  key,
  onProgress,
}) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise<{ Location: string }>((resolve, reject) => {
    reader.onload = () => {
      new S3({
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
        sessionToken,
        correctClockSkew: true,
        httpOptions: {
          timeout: 3600000, // 60 minutes
        },
      })
        .upload(
          {
            Bucket: bucket,
            Key: key,
            Body: reader.result as ArrayBuffer,
            ServerSideEncryption: 'AES256',
          },
          {
            partSize: 5 * 1024 * 1024,
            queueSize: 4,
            leavePartsOnError: true,
          },
        )
        .on('httpUploadProgress', (data) => onProgress(data))
        .send((err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
    };
  });
}

export async function uploadThumbnail({ S3, file, videoId, onProgress = (data) => {} }) {
  const ms = new Date().getTime();
  const ext = (file.type ? file.type.split('/') : file.name.split('.')).pop();
  const thumbnail_file_name = `HYFY-THUMBNAIL-${videoId}-${ms}.${ext}`;

  const {
    data: { bucket, thumbnailKey },
  } = await VideoAPI.get(`/apis/v1/videos/${videoId}/upload_info/`, {
    params: { thumbnail_file_name },
  });

  const {
    data: { accessKey, secretKey, sessionToken },
  } = await VideoAPI.get(`/apis/v1/videos/${videoId}/upload_token/`, {
    params: { key: btoa(thumbnailKey) },
  });

  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  return uploadToS3({
    file,
    S3,
    accessKey,
    secretKey,
    sessionToken,
    bucket,
    key: thumbnailKey,
    onProgress,
  });
}

interface UploadCaptions {
  S3: any;
  file: UploadedFile;
  videoId: number;
  captionsKey: string;
  onProgress: (data: any) => void;
}

export async function uploadCaptions({
  S3,
  file,
  videoId,
  captionsKey,
  onProgress = (data) => {},
}: UploadCaptions) {
  const {
    data: { bucket },
  } = await VideoAPI.get(`/apis/v1/videos/${videoId}/upload_info/`, {});
  const {
    data: { accessKey, secretKey, sessionToken },
  } = await VideoAPI.get(`/apis/v1/videos/${videoId}/upload_token/`, {
    params: { key: btoa(captionsKey) },
  });

  return uploadToS3({
    file,
    S3,
    accessKey,
    secretKey,
    sessionToken,
    bucket,
    key: captionsKey,
    onProgress,
  });
}

export async function fetchTeam() {
  const response = await VideoAPI.get('/apis/v1/team/');
  return response.data;
}

// education management APIs
export function fetchEducationContent(location) {
  let url = '/apis/v1/education/';

  if (location) {
    url += `?location=${location}`;
  }

  return VideoAPI.get(url);
}

export function markAsComplete({ id, cascade_name }) {
  return VideoAPI.patch(`/apis/v1/education/${id}/mark_completed/`, { cascade_name });
}

export async function createTranscode(videoId, jwt = '') {
  return VideoAPI.post(
    `/apis/v1/videos/${videoId}/transcode/`,
    Qs.stringify({ jwt, isFull: true }),
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
}

export async function checkTranscodeStatus(videoId, jwt) {
  return VideoAPI.get(
    `/apis/v1/videos/${videoId}/transcode/status/` + (jwt ? `?jwt=${jwt}` : ''),
  ).then(({ data }) => data);
}

export async function updateVideoView(videoId, videoViewId, data = {}) {
  if (!videoViewId) {
    //we don't have a view to update, might be self view
    return Promise.resolve(true);
  }

  return VideoAPI.patch(
    `/apis/v1/impressions/${videoViewId}/`,
    Qs.stringify({
      videoId,
      videoViewId,
      ...data,
    }),
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  ).then(({ data }) => data);
}

export async function createTranscribe(videoId) {
  return VideoAPI.post(`/apis/v1/videos/${videoId}/transcribe/`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function getTranscript(videoId) {
  try {
    const response = await VideoAPI.get(`/apis/v1/videos/${videoId}/get_transcript_as_text/`);
    return response.data;
  } catch (e: any) {
    if (e?.response?.status === 404) {
      return '';
    }
  }
}

export async function fetchZoomIntegrationStatus() {
  return VideoAPI.get('/apis/v1/integration/zoom/status/');
}

export async function cloneVideo(videoId) {
  return VideoAPI.post(`/apis/v1/videos/${videoId}/clone/`);
}

export function fetchDriftUser() {
  return CustomerAPI.get('/users/me');
}

export async function refreshUser() {
  return await VideoAPI.get('/apis/v1/users/refresh/');
}

// video library stuff
// fetch users videos with stats
export const fetchUserVideos = async ({
  team,
  sortKey,
  sortDescending = true,
  pageSize,
  pageNum,
  searchString = '',
  after = null,
  before = null,
  sourceType,
  user,
  folder,
}) => {
  // standard URL
  let url = '/apis/v1/videos/';

  // folder URLs
  if (!sourceType && folder) {
    if (isRootPersonalLibraryFolder(folder)) {
      url = '/apis/v1/folders/my-videos/';
    } else if (isRootTeamLibraryFolder(folder)) {
      url = '/apis/v1/folders/team-videos/';
    } else if (isHowToVideosFolder(folder)) {
      url = '/apis/v1/folders/how-to/';
    } else {
      url = `/apis/v1/folders/${folder.id}/`;
    }
  }

  const sortOrderIndicator = sortDescending ? '-' : '';

  const response = await VideoAPI.get(url, {
    params: {
      stats: true,
      team: team || undefined,
      source_type: sourceType,
      ordering: sortKey ? `${sortOrderIndicator}${sortKey}` : undefined,
      search: searchString,
      page_size: pageSize,
      page: pageNum,
      after,
      before,
      user,
    },
  });

  const { data } = response;

  const count = url === '/apis/v1/videos/' ? data.count : data.videos.count;
  const results = url === '/apis/v1/videos/' ? data.results : data.videos.results;
  const folders = data.folders || [];
  const fetchedFolder = {
    id: data.id,
    parentFolder: data.parentFolder,
    privacyStatus: data.privacyStatus,
  };

  return {
    count,
    results,
    folders,
    folder: fetchedFolder,
  };
};

export async function fetchUserVideosForReports({
  sortKey,
  sortDescending = true,
  after = null,
  before = null,
  filterMap = null,
  pageSize = 50,
  page = 1,
}) {
  const sortOrderIndicator = sortDescending ? '-' : '';
  const result = await VideoAPI.get('/apis/v1/videos/', {
    params: {
      stats: true,
      team: true,
      source_type: 1,
      page_size: pageSize,
      page,
      ordering: sortKey ? `${sortOrderIndicator}${sortKey}` : undefined,
      after,
      before,
      filterMap: filterMap,
    },
    timeout: 45000, // we add a timeout of 45 seconds for big videos
  });

  return result.data;
}

export async function fetchVideosReport({ startDate, endDate }) {
  const response = await VideoAPI.get(`/apis/v1/reporting/videos/all/`, {
    params: {
      export: true,
      ordering: 'view_count',
      start_date: startDate,
      end_date: endDate,
    },
  });

  return response.data;
}

export async function fetchFolders() {
  const response = await VideoAPI.get('/apis/v1/folders/');

  return response.data;
}

export async function createFolder({ title, parentFolder, privacyStatus }) {
  const response = await VideoAPI.post('/apis/v1/folders/', {
    title,
    parentFolder: parentFolder,
    privacyStatus: privacyStatus,
  });

  return response.data;
}

export async function updateFolder({ id, update }) {
  const response = await VideoAPI.patch(`/apis/v1/folders/${id}/`, {
    ...update,
  });

  return response.data;
}

export async function deleteFolder({ id }) {
  const response = await VideoAPI.delete(`/apis/v1/folders/${id}/`);

  return response.data;
}

export async function moveVideoToFolder({ videoId, folderId }) {
  const response = await VideoAPI.patch(`/apis/v1/folders/${folderId}/add/`, {
    video_id: videoId,
  });

  return response.data;
}

export async function makeOutreachSnippet(videoId) {
  const response = await VideoAPI.post(`/apis/v1/integration/outreach/snippet/${videoId}/`, {});
  return response.data;
}

// Share apis

export async function getGifTranscodeStatus({ videoId }) {
  const response = await VideoAPI.get(
    `/apis/v1/videos/${videoId}/gif-transcode/status?nocache=${Date.now()}/`,
  );

  return response.data;
}

export function fetchTranscodedGif({ videoId }) {
  const POLL_INTERVAL_MS = 3000;
  const MAX_RETRIES = 3;

  return new Promise((resolve, reject) => {
    const pollForStatus = async (tries = 0) => {
      try {
        const status = await getGifTranscodeStatus({ videoId });

        if (status.gif_url && status.gif_url.endsWith('.webm')) {
          Error('GIF transcoder returning .webm file instead of .gif!');
        }

        if (status.job_completed) {
          // WORKAROUND: Although the job claims to be completed, in practice
          // we'll get a 403 if we try to load it immediately. Give it a few
          // seconds to actually be ready. Below, we also retry if it still fails
          // to load.
          setTimeout(() => resolve(status.gif_url), POLL_INTERVAL_MS);
        } else {
          setTimeout(() => pollForStatus(0), POLL_INTERVAL_MS);
        }
      } catch (e) {
        // WORKAROUND: This call often 404s several times before it starts
        // returning a valid status, so we'll retry a bunch of times before
        // giving up.
        if (tries < MAX_RETRIES) {
          setTimeout(() => pollForStatus(tries + 1), POLL_INTERVAL_MS);
        } else {
          reject(e);
        }
      }
    };

    pollForStatus();
  });
}

export const fetchVideo = (videoId) => {
  const url = `/apis/v1/videos/${videoId}/`;
  return VideoAPI.get(url).then(({ data }) => data);
};

// fetch calendar status
export const getMeetingsStatus = () => MeetingsAPI.get(`/calendar/properties/status/v3`);

export const fetchUserCustomizationsInternal = (userId) => {
  return VideoAPI.get(`/apis/v1/users-customization/${userId}`).then(({ data }) => data);
};

// get user customizations
export const fetchUserCustomizations = (userId) => {
  return VideoAPI.get(`/apis/v1/player/users-customization/${userId}`).then(({ data }) => data);
};

// create or update user customizations
export const createOrUpdateUserCustomizations = (payload) => {
  return VideoAPI.post(`/apis/v1/users-customization/`, payload).then(({ data }) => data);
};

export const createOrUpdateTeamCustomizations = (payload) => {
  return VideoAPI.post(`/apis/v1/teams-customization/`, payload).then(({ data }) => data);
};

export const fetchLogoUploadInfo = async (fileExtension = 'jpg') => {
  const response = await VideoAPI.get('/apis/v1/users-customization/get_logo_upload_info/', {
    params: {
      fileExtension,
    },
  });

  return response.data;
};

// get user information
export const fetchUserInfo = async (userId): Promise<UserData> => {
  const response = await VideoAPI.get(`/apis/v1/users/${userId}`);
  return response.data;
};

// get storage regions
export const fetchStorageRegions = () => {
  return VideoAPI.get(`/apis/v1/storage/regions/`).then(({ data }) => data);
};

// update storage region
export const updatePreferredStorageRegion = (payload) => {
  return VideoAPI.post(`/apis/v1/storage/update/`, payload).then(({ data }) => data);
};

// get default privacy options list
export const fetchDefaultPrivacyOptions = () => {
  return VideoAPI.get(`/apis/v1/default-privacy/permissions/`).then(({ data }) => data);
};

// get custom privacy options list
export const fetchCustomPrivacyOptions = () => {
  return VideoAPI.get(`/apis/v1/privacy-team/`).then(({ data }) => data);
};

// update default privacy options
export const updateDefaultPrivacyOptions = (payload) => {
  return VideoAPI.post('/apis/v1/default-privacy/permissions/bulk/', payload).then(
    ({ data }) => data,
  );
};

// add new custom privacy option
export const addNewCustomPrivacyOption = (payload) => {
  return VideoAPI.post(`/apis/v1/privacy-team/`, payload).then(({ data }) => data);
};

// bulk add email domains for custom privacy options
export const bulkAddCustomEmails = (privacyGroupId, payload) => {
  return VideoAPI.post(`/apis/v1/privacy-team/${privacyGroupId}/permissions/bulk/`, payload).then(
    ({ data }) => data,
  );
};

// update custom privacy option name
export const updateCustomPrivacyOption = (privacyGroupId, payload) => {
  return VideoAPI.put(`/apis/v1/privacy-team/${privacyGroupId}/`, payload).then(({ data }) => data);
};

// fetch all email domains for custom privacy option
export const fetchCustomPrivacyOptionEmails = (privacyGroupId) => {
  return VideoAPI.get(`/apis/v1/privacy-team/${privacyGroupId}/permissions/`).then(
    ({ data }) => data,
  );
};

// delete custom privacy option
export const deleteCustomPrivacyOption = (privacyGroupId) => {
  return VideoAPI.delete(`/apis/v1/privacy-team/${privacyGroupId}/`).then(({ data }) => data);
};

export const fetchBotMessage = async () => {
  const response = await VideoAPI.get('/apis/v1/playbooks/welcome-message/');
  return response.data;
};

export const updateBotMessage = async (newGreeting: string, reportType: number) => {
  const response = await VideoAPI.post('/apis/v1/playbooks/welcome-message/', {
    greeting: newGreeting,
    reportType,
  });
  return response.data;
};

export interface BetaFeatureDetails {
  id: string;
  title: string;
  description: string;
}

export const fetchBetaFeaturesWithDetails = async (): Promise<BetaFeatureDetails[]> => {
  const response = await VideoAPI.get('/apis/v1/subscription/features/beta/details/');

  return response.data;
};

export const fetchBetaFeaturesEnabled = async (): Promise<string[]> => {
  const response = await VideoAPI.get('/apis/v1/subscription/user/beta/');

  return response.data;
};

export const addUserBetaFeature = async (id: string) => {
  const response = await VideoAPI.post('/apis/v1/subscription/user/beta/', {
    feature_id: id,
  });

  return response.data;
};

export const deleteUserBetaFeature = async (id: string) => {
  const response = await VideoAPI.delete(`/apis/v1/subscription/user/beta/${id}/`);

  return response.data;
};

// invalidate the cached calendar status value. Don't need jwt auth in this case because the user should be authed already
export const updateCalendarStatusCache = (userId: number, payload: object) => {
  return VideoAPI.patch(`/apis/v1/settings/calendar_status/${userId}/`, payload).then(
    ({ data }) => data,
  );
};

// Integrations

export const fetchIntegrationStates = () => {
  return CustomerAPI.get('/integrations/all/public').then(({ data }) => data);
};

export async function regenerateGifCaptions(videoId: number, captionFileName) {
  return await VideoAPI.post(`/apis/v1/videos/${videoId}/gif-transcode/`, { captionFileName });
}
